import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import Arrow from "@app-components/utils/arrow"
import Background from "@app-components/utils/background"
import Icon from "@app-components/utils/icon"
import { itemCSS, link, image } from "./styles.module.scss"

class Showcase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      image_opacity: 1
    }
    this.onFocus = active => this.toggle(active)
    this.onMouseEnter = active => this.toggle(active)
    this.onItemClick = active => this.toggle(active)
  }
  onLinkClick(e, prevent) {
    if (prevent) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  toggle(active) {
    if (active !== this.state.active) {
      document.getElementById(`showcase_item_${active}`).focus()
      this.setState(
        {
          image_opacity: 0
        },
        () => {
          setTimeout(() => {
            this.setState({
              image_opacity: 1,
              active
            })
          }, 250)
        }
      )
    }
  }
  render() {
    const { items, background } = this.props
    const { active } = this.state
    return (
      <Background tagName="section" className="bg-cover bg-no-repeat bg-center px-2 md-px-3 px2 py5" type={background}>
        <div className="relative">
          <div className="block lg-flex max-width-5 mx-auto items-stretch">
            <div className="mx-auto col-12 sm-col-10 lg-col-5">
              {items.map((item, index) => (
                <div key={item.title}>
                  <button
                    onClick={() => this.onItemClick(index)}
                    onMouseEnter={() => this.onMouseEnter(index)}
                    onFocus={() => this.onFocus(index)}
                    style={{
                      boxShadow: active === index ? "0 8px 16px 0 rgba(56,62,67,0.11)" : "",
                      borderRadius: "8px",
                      background: active === index ? "white" : ""
                    }}
                    id={`showcase_item_${index}`}
                    className={`
                        py0 px0 sm-py3 sm-px3 md-px2 xl-px3 block sm-flex items-start normalized-btn mb2
                        ${itemCSS}`}>
                    <Icon
                      size="48"
                      className="mb2"
                      icon={item.icon}
                      style={{
                        marginTop: "-.5rem"
                      }}
                    />
                    <div className="pl0 sm-pl2 flex-auto">
                      <h4>{item.title}</h4>
                      <p style={{ fontSize: "14px" }} className="mt1">
                        {item.description}
                      </p>
                      <div className="block sm-hide md-hide lg-hide">
                        <Link
                          className="bold flex items-center"
                          onClick={e => this.onLinkClick(e, active !== index)}
                          style={{ fontSize: "16px" }}
                          to={item.link.to}>
                          <div className="red">{item.link.text}</div>
                          <Arrow className="block ml1" color="red" />
                        </Link>
                      </div>
                      <div className="xs-hide sm-block mt2">
                        {active === index ? (
                          <Link
                            className={`${link} bold flex items-center`}
                            style={{ fontSize: "16px" }}
                            to={item.link.to}>
                            <div className="red">{item.link.text}</div>
                            <Arrow className="block ml1" color="red" alt="View Page" />
                          </Link>
                        ) : (
                          <div style={{ height: "20px" }} />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="block sm-hide md-hide lg-hide mb4 mt3">{item.image}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                opacity: this.state.image_opacity,
                transition: "opacity .3s linear"
              }}
              className={`mx-auto xs-hide sm-block col-12 sm-col-10 lg-col-7 pl0 lg-px0 lg-pl4 relative p2 overflow-hidden  ${image}`}>
              {items.find((item, index) => index === active).image}
            </div>
          </div>
        </div>
      </Background>
    )
  }
}

Showcase.propTypes = {
  items: PropTypes.array.isRequired,
  background: PropTypes.string.isRequired
}

export default Showcase
