import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TeamImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/home_team.png/" }) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Img alt="Work together as a team on CineSend" fluid={data.file.childImageSharp.fluid} />}
  />
)

export default TeamImage
