import React from "react"
import Plx from "react-plx"
import Link from "gatsby-link"
import Player from "@app-components/ui/player/"
import Arrow from "@app-components/utils/arrow"
import Icon from "@app-components/utils/icon"
import { bg } from "./styles.module.scss"

class Screeners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: null
    }
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.updateHeight()
  }
  set(ref) {
    this.element = ref
    this.updateHeight()
  }
  updateHeight() {
    if (this.element) {
      const height = this.element.getBoundingClientRect().height
      if (height !== this.state.height) {
        this.setState({ height })
      }
    }
  }
  render() {
    return (
      <section className="relative px2 md-px-3 py5" ref={ref => this.set(ref)} id="fixed-sticky-trigger">
        <Plx
          className={`fixed top-0 left-0 right-0 bottom-0 z1 ${bg}`}
          parallaxData={[
            {
              start: "#fixed-sticky-trigger",
              duration: 100,
              startOffset: 300,
              properties: [
                {
                  startValue: 0,
                  endValue: 1,
                  property: "opacity"
                }
              ]
            },
            {
              start: "#fixed-sticky-trigger",
              duration: 200,
              startOffset: this.state.height + 200,
              properties: [
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity"
                }
              ]
            }
          ]}
        />
        <div className="center relative white z1">
          <div className="max-width-3 mx-auto mb4">
            <Player delay />
          </div>
          <Plx
            className="center"
            parallaxData={[
              {
                start: "self",
                duration: 300,
                properties: [
                  {
                    startValue: 40,
                    endValue: 0,
                    property: "translateY"
                  }
                ]
              }
            ]}>
            <Icon
              style={{
                background: "white"
              }}
              size="64"
              icon="play-black"
              className="inline-block"
            />
            <h2>Online Screeners</h2>
            <p className="large-p-tag max-width-3 mx-auto mt1">
              An online HD screener experience your reviewers will actually enjoy - with no security compromise.
              PiracyDetector™ keeps your content safe, and powerful analytics let you know whos watching what.
            </p>
            <div id="absolute-sticky-trigger" />
            <Link
              to="/products/files/features/online-screeners"
              style={{ fontSize: "18px" }}
              className="bold mt1 inline-block"
              title="View More Screener Information">
              <div className="flex items-center">
                <span className="white">Learn more about online screeners</span>
                <Arrow className="block ml1" color="white" />
              </div>
            </Link>
          </Plx>
        </div>
      </section>
    )
  }
}

export default Screeners
