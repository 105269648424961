import React from "react"
import Layout from "@app-components/layout/"
import Showcase from "@app-components/ui/showcase/"
import Feature from "@app-components/ui/feature/"
import Header from "@app-components/ui/header/side_by_side/"
import Screeners from "@page-components/products/files/features/online-screeners/"
import StorageImage from "@page-components/products/media/showcase/storage/"
import ConvertImage from "@page-components/products/media/showcase/convert/"
import DeliverImage from "@page-components/products/media/showcase/deliver/"
import HeaderImage from "@page-components/products/media/header/"
import Team from "@page-components/products/media/team"
import HEADERS from "@data/headers/"

const Files = () => (
  <Layout
    getStartedButtons={["demo", "get-started"]}
    page="products/files"
    getStartedText="Sign up today for a free 30-day trial and start managing your media like a pro.">
    <Header
      title="CineSend Files"
      text="Store, convert, and deliver your massive master files, small documents, or anything in between."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.files}
      buttons={[
        {
          text: <div className="red">Get Started</div>,
          className: "web_white",
          type: "link",
          link: {
            to: "https://accounts.cinesend.com",
            type: "external"
          }
        },
        {
          text: "Schedule a Demo",
          className: "web_white_outline ",
          type: "link",
          link: {
            to: "/demo",
            type: "internal"
          }
        }
      ]}
    />
    <Showcase
      background="gray-single"
      items={[
        {
          title: "Media Conversion",
          description:
            "Easily convert between professional formats like ProRes, DCP, MXF, or MP4 with a single click. Adding captions and subtitles is just as easy.",
          icon: "convert-white",
          image: <ConvertImage />,
          link: {
            to: "/products/files/features/media-conversion",
            text: "Learn more about media conversion"
          }
        },
        {
          title: "Content Delivery",
          description:
            "Send reliable high-speed download links with no file size limits. Set custom expiry rules and track downloads to make sure everything arrives on time.",
          icon: "send-white",
          image: <DeliverImage />,
          link: {
            to: "/products/files/features/media-delivery",
            text: "Learn more about content delivery"
          }
        },
        {
          title: "Cloud Storage",
          description: `More reliable than physical media, CineSend offers a safe and secure long-term home for your content that's accessible from wherever you are.`,
          icon: "upload-white",
          image: <StorageImage />,
          link: {
            to: "/products/files/features/media-delivery",
            text: "Learn more about cloud storage"
          }
        }
      ]}
    />
    <Screeners />
    <Feature
      title="Work as a team"
      text="Add colleagues to collaborate on projects. Advanced role-based permissions and override rules let you control who has access to what."
      link={{
        to: "/products/files/features/teams",
        label: "Learn more about teams"
      }}
      background="gray-double"
      icon="users-white"
      image={<Team />}
    />
  </Layout>
)

Files.propTypes = {}

export default Files
