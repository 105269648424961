import React from "react"
import PropTypes from "prop-types"
import { useMount, useUnmount } from "react-use"
import { css } from "@emotion/core"
import thumbnail from "@media/player/thumbnail.jpg"
import "./player.scss"

const Player = ({ delay }) => {
  const load = () => {
    bitmovin.player("player").setup({
      key: "2a8b1f24-2c3c-4ab1-adeb-2b89af5171f0",
      source: {
        dash:
          "https://dowuwd6v9jrq0.cloudfront.net/953210-60edea22db4e065e094663c0/1626204721/60edea2a35c5303e5c460ffa.mpd",
        hls:
          "https://d1m69aikzkbtsz.cloudfront.net/953210-60edea22db4e065e094663c0/1626204721/60edea2a35c5303e5c460ffa.m3u8",
        progressive: [
          {
            url: "https://d196gw7y0yn04q.cloudfront.net/demo/mp4/cinesend.mp4",
            type: "video/mp4"
          }
        ],
        poster: thumbnail,
        drm: {
          widevine: {
            LA_URL: "https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=DFC05E"
          },
          playready: {
            LA_URL: "https://playready.ezdrm.com/cency/preauth.aspx?pX=9F239B"
          },
          fairplay: {
            LA_URL: "https://fps.ezdrm.com/api/licenses/AC35961F-D210-481D-8731-0030CAC399BA",
            certificateURL: "https://d1bejs8r9quxzj.cloudfront.net/fairplay/fairplay.cer",
            prepareContentId(id) {
              const uri = id
              let uriParts = uri.split("://", 1)
              const protocol = uriParts[0].slice(-3)
              uriParts = uri.split(";", 2)
              let contentId = uriParts.length > 1 ? uriParts[1] : ""
              uriParts = contentId.split("?", 2)
              contentId = uriParts.length > 1 ? uriParts[0] : contentId
              return protocol.toLowerCase() === "skd" ? contentId : ""
            },
            prepareLicenseAsync(ckc) {
              // eslint-disable-next-line func-names
              return new Promise(function(resolve, reject) {
                const reader = new FileReader()
                // eslint-disable-next-line func-names
                reader.addEventListener("loadend", function() {
                  resolve(new Uint8Array(reader.result))
                })
                // eslint-disable-next-line func-names
                reader.addEventListener("error", function() {
                  reject(reader.error)
                })
                reader.readAsArrayBuffer(ckc)
              })
            },
            prepareMessage(event) {
              return new Blob([event.message], { type: "application/octet-binary" })
            },
            headers: [
              {
                name: "content-type",
                value: "application/octet-stream"
              }
            ],
            useUint16InitData: true,
            licenseResponseType: "blob"
          }
        }
      }
    })
  }
  useMount(() => {
    setTimeout(
      () => {
        if (!window.bitmovinLoaded) {
          const script = document.createElement("script")
          script.src = "https://bitmovin-a.akamaihd.net/bitmovin-player/stable/7.8.0/bitmovinplayer.js"
          script.onload = () => {
            window.bitmovinLoaded = true
            load()
          }
          document.head.appendChild(script)
        } else {
          load()
        }
      },
      delay ? 1500 : 0
    )
  })
  useUnmount(() => {
    if (window.bitmovinLoaded && bitmovin.player("player").isSetup()) {
      bitmovin.player("player").destroy()
    }
  })
  return (
    <div
      css={css`
        &:after {
          content: "";
          padding-top: 56.25%;
          display: block;
        }
        .bmpui-ui-fullscreentogglebutton {
          display: none;
        }
        .bmpui-ui-hugereplaybutton {
          background: none !important;
        }
      `}
      className="relative">
      <div className="absolute top-0 left-0 right-0 bottom-0" id="player" />
    </div>
  )
}

Player.defaultProps = {
  delay: false
}

Player.propTypes = {
  delay: PropTypes.bool
}
export default Player
