import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ShowcaseImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/home_features_convert.png/" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Img alt="Convert Files on CineSend" fluid={data.file.childImageSharp.fluid} />}
  />
)

export default ShowcaseImage
